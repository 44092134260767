<template>
  <div id="startform_page" v-loading.fullscreen.lock="loading">
		<div class="container">
			<!-- Step one -->
			<div class="startform-step step-one" v-if="active_step == 1">
				<div class="start-icon-container">
					<div class="start-icon">
						<img :src="`${publicPath}img/learning-icon.svg`" alt="">
					</div>
				</div>

				<span style="display: block; font-size: 22px; text-align: center; margin: 20px 0 10px 0;">{{ company.small_name ? `Welcome to ${company.small_name} Carpooling!` : 'Welcome to carpooling!' }}</span>
				<span style="display: block; text-align: center">To participate in this pilot you need to be willing to provide or accept a lift in your or your companion’s car on the commute to and from DCU.  The app will help you find other commuters to share your journey with. You will always be free to accept or reject other people’s requests to carpool.<br>Please complete the signup form if you are interested in participating. Make sure to <b>use you college email address</b> to start.</span>
				
				<div style="margin-top: 30px;">
					<form-item label="Full name">
						<el-input v-model="info.full_name" placeholder="Your full name"></el-input>
					</form-item>
				</div>

				<div style="margin-top: 15px;">
					<form-item label="Email">
						<el-input v-model="info.email" type="email" placeholder="Your college email address"></el-input>
					</form-item>
				</div>

				<div style="margin-top: 15px;">
					<form-item label="Password">
						<el-input v-model="info.password" type="password" placeholder="Create a password for your account"></el-input>
					</form-item>
				</div>

				<div style="margin-top: 15px;">
					<el-checkbox v-model="agree_privacy_policy"></el-checkbox>&nbsp;&nbsp;<span>I have read and accept the <router-link class="highlighted" to="/terms-conditions">Terms and Conditions</router-link> and the <router-link class="highlighted" to="/privacy-policy">Privacy Policy</router-link>.</span>
				</div>

			</div>

			<!-- Step two -->
			<div class="startform-step step-two" v-if="active_step == 2">

				<div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/testing-icon.svg`" alt="">
					</div>
				</div>

				<br>
				
				<div id="startform_location" class="startform-group">
					<span class="question-title">1. Where do you commute from?</span>
					<span style="display: block">We need this to find carpooling matches around your area, it won't be shared with anyone.</span>
					<el-input @change="validateAddress" :class="{w100: true, 'validate-success': info.address.validated}" style="margin-top: 8px" placeholder="Street name" v-model="info.address.input_address">
						<el-button @click="validateAddress" type="primary" :icon="info.address.validated ? 'el-icon-check' : ''" slot="append">{{ info.address.validated ? 'Verified' : 'Verify' }}</el-button>
					</el-input>
				</div>

				<div id="startform_organization" class="startform-group">
					<span class="question-title">2. What location do you commute to?</span>

					<div v-for="(org_val, org_key) in company.organizations" :key="org_key">
						<div v-for="(loc_val, loc_id) in org_val.locations" :key="loc_id" class="surveyInputGroup">
							<input :id="loc_id" name="survey-location" type="radio" v-model="info.organization_location" :value="`${org_key}###${loc_id}`">
							<label :for="loc_id">{{ Object.keys(company.organizations).length > 1 ? `${org_val.name} - ${loc_val.name}` : loc_val.name }}</label>
						</div>
					</div>
				</div>

				<div id="startform_days_of_week" class="startform-group">
					<span class="question-title">3. What days of the week?</span>

					<div style="margin-top: 2px" class="fancy-checkboxes">
						<div>
							<input type="checkbox" id="days_of_week_checkbox_monday" v-model="info.days_of_week.monday">
							<label for="days_of_week_checkbox_monday">Monday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_tuesday" v-model="info.days_of_week.tuesday">
							<label for="days_of_week_checkbox_tuesday">Tuesday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_wednesday" v-model="info.days_of_week.wednesday">
							<label for="days_of_week_checkbox_wednesday">Wednesday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_thursday" v-model="info.days_of_week.thursday">
							<label for="days_of_week_checkbox_thursday">Thursday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_friday" v-model="info.days_of_week.friday">
							<label for="days_of_week_checkbox_friday">Friday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_saturday" v-model="info.days_of_week.saturday">
							<label for="days_of_week_checkbox_saturday">Saturday</label>
						</div>

						<div>
							<input type="checkbox" id="days_of_week_checkbox_sunday" v-model="info.days_of_week.sunday">
							<label for="days_of_week_checkbox_sunday">Sunday</label>
						</div>
					</div>
				</div>

				<div id="startform_gotimes" class="startform-group">
					<span class="question-title">4. Commuting to college (approx.)</span>
					<el-row style="margin-top: 8px" :gutter="20">
						<el-col :sm="11" style="margin-bottom: 10px">
							<el-time-select
								class="w100"
								placeholder="Time leaving home"
								v-model="info.commuting_times.go_leaving_time"
								:picker-options="{
									start: '05:30',
									step: '00:15',
									end: '15:00'
								}"></el-time-select>
						</el-col>
						<el-col :sm="13">
							<el-input class="w100" type="number" placeholder="Trip duration" v-model="info.commuting_times.go_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</el-col>
					</el-row>		
				</div>

				<div id="startform_backtimes" class="startform-group">
					<span class="question-title">5. Commuting back home (approx.)</span>
					<el-row style="margin-top: 8px" :gutter="20">
						<el-col :sm="11" style="margin-bottom: 10px">
							<el-time-select
								class="w100"
								placeholder="Time leaving office"
								v-model="info.commuting_times.back_leaving_time"
								:picker-options="{
									start: '12:00',
									step: '00:15',
									end: '23:45'
								}"></el-time-select>
						</el-col>
						<el-col :sm="13">
							<el-input class="w100" type="number" placeholder="Trip duration" v-model="info.commuting_times.back_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</el-col>
					</el-row>
					
				</div>

				<div id="startform_carpool_type" class="startform-group">
					<span class="question-title">6. Carpool role</span>
					<span style="display: block">Please choose if you would like to be a driver, a passenger or can be either.</span>
					<div class="surveyInputGroup">
						<input id="driver-option-type-survey" name="survey-carpool-type" type="radio" v-model="info.carpool_type" value="driver">
						<label for="driver-option-type-survey">Driver</label>
					</div>

					<div class="surveyInputGroup">
						<input id="passenger-option-type-survey" name="survey-carpool-type" type="radio" v-model="info.carpool_type" value="passenger">
						<label for="passenger-option-type-survey">Passenger</label>
					</div>

					<div class="surveyInputGroup">
						<input id="both-option-type-survey" name="survey-carpool-type" type="radio" v-model="info.carpool_type" value="both">
						<label for="both-option-type-survey">Either</label>
					</div>
				</div>

				<div id="startform_agegroup" class="startform-group">
					<span class="question-title">7. What's your age group?</span>
					<span style="display:block;">This helps us prioritise matches of people of similar age, where possible.</span>

					<div class="surveyInputGroup">
						<input id="one-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="22">
						<label for="one-option-age-survey">Less than 26 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="two-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="30">
						<label for="two-option-age-survey">26 to 35 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="three-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="40">
						<label for="three-option-age-survey">36 to 45 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="four-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="50">
						<label for="four-option-age-survey">46 to 55 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="five-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="60">
						<label for="five-option-age-survey">56 to 65 years old</label>
					</div>

					<div class="surveyInputGroup">
						<input id="six-option-age-survey" name="survey-age-group" type="radio" v-model="info.age" :value="70">
						<label for="six-option-age-survey">More than 65 years old</label>
					</div>

				</div>

				<div id="startform_agegroup" class="startform-group">
					<span class="question-title">8. What's your gender?</span>
					<span style="display:block;">This helps us prioritise matches of people of same gender, where possible.</span>
					<div class="surveyInputGroup">
						<input id="one-option-gender-survey" name="survey-gender-group" type="radio" v-model="info.gender" value="male">
						<label for="one-option-gender-survey">Male</label>
					</div>

					<div class="surveyInputGroup">
						<input id="two-option-gender-survey" name="survey-gender-group" type="radio" v-model="info.gender" value="female">
						<label for="two-option-gender-survey">Female</label>
					</div>

					<div class="surveyInputGroup">
						<input id="three-option-gender-survey" name="survey-gender-group" type="radio" v-model="info.gender" value="other">
						<label for="three-option-gender-survey">Other / I prefer not to specify</label>
					</div>
				</div>

				<div v-if="info.carpool_type == 'both' || info.carpool_type == 'driver'" id="startform_car_reg" class="startform-group">
					<span class="question-title">9. Your car registration plate</span>
					<span style="display: block">If you park in carpooling-only places, we need this to verify you.</span>
					<el-input class="w100" style="margin-top: 8px" placeholder="191 D 1234" v-model="info.car_reg"></el-input>
				</div>
			</div>

			<!-- Step three -->
			<div class="startform-step step-three" v-if="active_step == 3 && false">
				<!-- <div id="startform_photo" class="startform-group">
					<span class="question-title">A profile photo</span>
					<span style="display:block">Adding your photo creates a sense of trust and help your carpooling buddies recognise you.</span>
					<user-avatar-upload style="margin-top: 8px" :upload-location="`_temp/${short_id}`"></user-avatar-upload>
				</div> -->
				<div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/testing-icon.svg`" alt="">
					</div>
				</div>

				<span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 0 0;">You're nearly there!</span>
				<span style="display: block; text-align: center">We only need these last few details to complete your profile.</span>
				

				<br>

				<div id="startform_description" class="startform-group">
					<span class="question-title">Tell us a bit about yourself</span>
					<el-input class="w100" :rows="3" style="margin-top: 8px" type="textarea" placeholder="(optional) What do you think your future carpooling buddy would like to know about you?" v-model="info.description"></el-input>
				</div>

				<div id="startform_phone_number" class="startform-group">
					<span class="question-title">Your phone number</span>
					<span style="display: block">Needed to arrange pickup information with your carpooling partners.</span>
					<div style="display: flex; align-items: center; margin-top: 8px">
						<el-select style="width: 90px" v-model="info.phone_number_code">
							<el-option v-for="country in getCountries" :key="country" :label="country" :value="country"></el-option>
						</el-select>
						<el-input :class="{validated: formatted_phone_number && formatted_phone_number.isValid(), 'not-validated': ((!formatted_phone_number || !formatted_phone_number.isValid()) && info.phone_number) }" placeholder="08XXXXXXXX" v-model="info.phone_number"></el-input>
					</div>
				</div>

				<div v-if="info.carpool_type != 'passenger'" id="startform_car_reg" class="startform-group">
					<span class="question-title">Your car registration plate</span>
					<span style="display: block">If you park in carpooling-only places, we need this to verify you.</span>
					<el-input class="w100" style="margin-top: 8px" placeholder="191 D 1234" v-model="info.car_reg"></el-input>
				</div>
			</div>

			<!-- Step four -->
			<div class="startform-step step-four" v-if="active_step == 4">
				<div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/email-check.png`" alt="">
					</div>
				</div>

				<span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Thanks for joining!</span>

				<span style="display: block; text-align: center">Once you log in and verify your profile, you will enter the draw of a €50 gift card when we launch.</span>
				<span style="display: block; text-align: center; margin-top: 15px">You can download the app on your <span style="font-weight: 500">iPhone</span> or <span style="font-weight: 500">Android Phone</span>.</span>
				
				<!-- If in PWA -->
				<div v-if="isPWA()" style="display: flex; align-center: center; justify-content: center;margin-top: 20px">
					<el-button type="primary" class="secondary-color" round @click="onLoginClicked">Go to app</el-button>
				</div>

				<!-- If not PWA and on mobile -->
				<div v-else-if="!isPWA() && (getOperatingSystem() == 'iOS' && getOperatingSystem() == 'Android')" style="display: flex; align-center: center; justify-content: center;margin-top: 20px">
					<el-button type="primary" class="secondary-color" round @click="$router.push('/download')">Download app</el-button>
					<el-button type="info" class="secondary-color" round plain @click="onLoginClicked">Login instead</el-button>
				</div>

				<!-- If in not in iOS or Android (i.e. desktop) -->
				<div v-else style="display: flex; align-center: center; justify-content: center;margin-top: 20px">
					<el-button type="primary" class="secondary-color" round @click="$router.push('/download')">How to download app</el-button>
					<!-- <el-button type="info" class="secondary-color" round plain @click="onLoginClicked">Login instead</el-button> -->
				</div>

			</div>
		</div>

		

		<div v-if="button_text[active_step-1]" class="form-navigation-buttons">
			<div class="navigation-button">
				<el-button :disabled="loading" style="font-size: 17px" type="primary" class="secondary-color" @click="onSubmitForm" round>{{ button_text[active_step-1] }} &nbsp;<i class="el-icon-arrow-right el-icon-arrow-right"></i></el-button>
			</div>
		</div>
		
    
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import FormItem from '@/components/Form/FormItem';
import RequestHappinessLevel from '@/components/RequestHappinessLevel';
import moment from 'moment';
import UserAvatarUpload from '@/components/UserAvatarUpload';
import { parsePhoneNumberFromString, getCountries, AsYouType } from 'libphonenumber-js/mobile';

export default {
	components: { PageTitle, FormItem, RequestHappinessLevel, UserAvatarUpload },
	data() {
		return {
			short_id: this.generateShortID(),
			agree_privacy_policy: false,
			info: {
				email: null,
				password: '',
				full_name: '',
				carpool_type: null,
				organization_location: null,
				phone_number: null,
				car_reg: null,
				days_of_week: {
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false
				},
				address: {
					input_address: '',
					address_formatted: '',
					address_location: {},
					address_place_id: null,
					validated: false
				},
				age: null,
				gender: null,
				commuting_times: {
					go_leaving_time: null,
					go_commute_duration: null,
					back_leaving_time: null,
					back_commute_duration: null
				},
				description: '',
				phone_number: '',
				phone_number_code: 'IE',
				car_reg: ''
			},
			company: {
				organizations: {}
			},
			submit_loading: false,
			active_step: 1,
			button_text: ['Start', 'Next', 'Finish', '', null],
			loading: false,
			publicPath: process.env.BASE_URL
		}
	},

	methods: {
		onSubmitForm() {
			if (this.active_step == 1) {
				return this.submitStepOne();
			} else if (this.active_step == 2) {
				return this.submitStepTwo();
			} else if (this.active_step == 3) {
				return this.submitStepThree();
			} else {
				return this.submitStepFour();
			}
		},

		// To submit step one
		async submitStepOne() {
			// Check agree
			if (!this.agree_privacy_policy) {
				return this.$message({ type: 'error', showClose: true, message: "Please accept our terms & conditions and privacy policy" });
			}

			// Check password
			if (!this.info.password.trim() || this.info.password.length < 6) {
				return this.$message({ type: 'error', showClose: true, message: 'Invalid password, at least 6 characters' });
			}


			if (this.validateEmail(this.info.email.trim()) && this.info.full_name.trim() != "") {
				// Sign up and if good, go to step 2
				this.loading = true;
				this.info.email = this.info.email.trim();

				let company = null;

				try {
					let _data = await this.SharoAPI({ action: 'getPublicCompany', data: { email_domain: this.info.email.replace(/.*@/, "").toLowerCase() }});

					if (_data.data.error) {
						this.loading = false;
						return this.$message({ type: 'error', showClose: true, message: _data.data.error_message });
					}

					company = _data.data.data;

				} catch (err) {
					this.loading = false;
					return this.$message({ type: 'error', showClose: true, message: 'An error occurred internally, please try again' });
				}

				// Set company
				this.company = company;

				this.$firebase.auth().fetchSignInMethodsForEmail(this.info.email).then(result => {
					this.loading = false;

					// If the user already exists
					if (result.length > 0) {
						this.$message({ type: 'info', showClose: true, message: 'You already have an account with that email, please login instead'});
						this.$router.push({name: 'login', query: { email: this.info.email}});
					} else {
						this.active_step = 2;
					}
				});
				

			} else {
				this.$message({  message: 'Error, we need a valid email and name', showClose: true, type: 'error' });
			}
		},

		async submitStepTwo() {
			let form_data = this.getPostingData();
			this.loading = true;

			// If address is not verified
			if (!this.info.address.validated) {
				this.loading = false;
				return this.$message({ type: 'error', showClose: true, message: 'Please enter a valid address' });
			}
			
			// If not all data has been submitted
			if (!form_data) {
				this.loading = false;
				return this.$message({ type: 'error', showClose: true, message: 'Please fill in all details' });
			}

			this.submitRegistrationInfo(form_data);
		},

		submitStepThree() {
			if (!parsePhoneNumberFromString(this.info.phone_number, this.info.phone_number_code) || !parsePhoneNumberFromString(this.info.phone_number, this.info.phone_number_code).isValid()) {
				return this.$message({ type: 'error', showClose: true, message: 'Please enter a valid phone number' });
			}

			let form_data = this.getPostingData('full');

			if (!form_data) {
				return this.$message({ type: 'error', showClose: true, message: 'Please put a valid phone number and the rest' });
			}

			this.submitRegistrationInfo(form_data);
		},

		// Function to validate address
		async validateAddress() {
			let response = await this.geocode(this.info.address.input_address);
			console.log(response.results[0])
			
			if (response.results.length < 1) {
				this.info.address.validated = false;
				return this.$message({ type: 'error', showClose: true, message: 'Cannot find address, please try again'});
			}

			this.info.address.validated = true;
			this.info.address.input_address = response.results[0].formatted_address;
			this.info.address.address_formatted = response.results[0].formatted_address;
			this.info.address.address_location.lat = response.results[0].geometry.location.lat;
			this.info.address.address_location.lng = response.results[0].geometry.location.lng;
			this.info.address.address_place_id = response.results[0].place_id;
		},

		// Function to get all the data in a format to send to server
		getPostingData(optional = null) {
			if (!(this.info.organization_location 
			&& this.info.commuting_times.go_leaving_time 
			&& this.info.commuting_times.go_commute_duration
			&& this.info.commuting_times.back_leaving_time
			&& this.info.commuting_times.back_commute_duration
			&& this.info.address.validated
			&& this.info.carpool_type
			&& this.info.gender
			&& this.info.age)) {
				return null;
			}

			let _org = this.info.organization_location.split('###')[0];
			let _office = this.info.organization_location.split('###')[1];

			// If organization and office don't exist
			if (!this.company.organizations[_org] || !this.company.organizations[_org].locations[_office]) {
				return null;
			}

			if (optional == 'full') {
				if (!this.formatted_phone_number || !this.formatted_phone_number.isValid()) {
					return null;
				}
			}

			let title_full_name = this.titleize(this.info.full_name.trim());

			return {
				email: this.info.email,
				_password: this.info.password,
				first_name: title_full_name.split(' ')[0],
				last_name: title_full_name.split(' ')[1] || '',
				age: this.info.age,
				commuting_times: {
					go_leaving_time: moment(this.info.commuting_times.go_leaving_time, 'HH:mm').toISOString(),
					go_arriving_time: moment(this.info.commuting_times.go_leaving_time, 'HH:mm').add(this.info.commuting_times.go_commute_duration, 'minutes').toISOString(),
					back_leaving_time: moment(this.info.commuting_times.back_leaving_time, 'HH:mm').toISOString(),
					back_arriving_time: moment(this.info.commuting_times.back_leaving_time, 'HH:mm').add(this.info.commuting_times.back_commute_duration, 'minutes').toISOString(),
				},
				gender: this.info.gender,
				default_transport_mode: this.info.carpool_type == 'passenger' ? 'public_transport' : 'drive',
				initial_transport_mode: this.info.carpool_type == 'passenger' ? 'public_transport' : 'drive',
				initial_happiness_level: 3,
				happiness_level: 3,
				commuting_distance: this.calculateDistance(this.company.organizations[_org].locations[_office].location, this.info.address.address_location),
				commuting_duration: Number(this.info.commuting_times.go_commute_duration),
				address: {
					address_formatted: this.info.address.address_formatted,
					address_location: this.info.address.address_location,
					address_place_id: this.info.address.address_place_id
				},
				organization: this.info.organization_location.split('###')[0],
				office: this.info.organization_location.split('###')[1],
				car_reg_plate: this.info.car_reg,
				phone_number: this.formatted_phone_number && this.formatted_phone_number.number || '',
				commuting_days_of_week: this.info.days_of_week,
				carpooling_details: {
					driver: this.info.carpool_type == 'both' || this.info.carpool_type == 'driver',
					passenger: this.info.carpool_type == 'both' || this.info.carpool_type == 'passenger',
					description: this.info.description,
					available: true
				},
				temp_photo_id: this.short_id
			}
		},

		submitRegistrationInfo(form_data) {
			this.loading = true;

			this.SharoAPI({ action: 'createUserWithRegistrationInfo', data: form_data }).then(result => {
				if (result.data.error) {
					return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
				}

				localStorage.setItem("emailForSignIn", this.info.email);

				this.$message({ type: 'success', showClose: true, message: result.data.message });

				this.active_step = 4;
			}).finally(_ => this.loading = false);
		},

		onLoginClicked() {
			this.loading = true;

			this.$store.dispatch('login', { email: this.info.email, password: this.info.password }).then(result => {
				this.$router.push('/').catch(() => {});
			}).catch(err => {
				console.log(err);
				this.$router.push(`/login?email=${encodeURI(this.info.email)}`).catch(() => {});
			}).finally(() => this.loading = false);
		}
	},

	mounted() {
		this.info.email  = this.$route.query.email || '';

		if (this.info.email) {
			let name_splits = this.info.email.split('@')[0].split('.');

			// If  format 'fname.lname@company.com'
			if (name_splits.length > 1) {
				this.info.full_name = name_splits.map(str => str.charAt(0).toUpperCase() + str.slice(1) ).join(' ');
			}
		}

		// If company is set on the query
		if (this.$route.query.company) {
			this.SharoAPI({ action: 'getPublicCompany', data: { _id: this.$route.query.company} }).then(result => {
				console.log(result);
				if(result.data.success) {
					this.company = result.data.data;
				}
			})
		}
	},

	computed: {
		formatted_phone_number() {
			return parsePhoneNumberFromString(this.info.phone_number, this.info.phone_number_code);
		},

		getCountries() {
			return getCountries();
		}
	}
}
</script>

<style lang="scss" scoped>
	@import 'start_form.scss';

	.el-input.validated {
		border: 1px solid green !important;
		border-radius: 5px;
	}

	.el-input.not-validated {
		border: 1px solid red !important;
		border-radius: 5px;
	}

	.el-col {
		margin-bottom: 0;
	}

	#startform_page {
		display: block;
		width: 100%;

		#startform_organization {
			margin-top: 45px !important;
		}

		#startform_location, #startform_description {
			margin-top: 15px;
		}
		
	}

	.startform-step {
		width: 100%;
		margin-bottom: 90px;

		&.step-one, &.step-two, &.step-three, &.step-four {
			.start-icon-container {
				width: 100%;
				display: flex;
				justify-content: center;

				.start-icon {
					width: 150px;
					height: 150px;
					padding: 20px;
					// border: 1px solid blue;
					background-color: #d1f1ff;
					border-radius: 50%;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	.form-navigation-buttons {
		z-index: 11000;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 0;
		padding: 10px 10px 20px 10px;
		width: 100%;
		background: linear-gradient(rgba(255, 255, 255, 0.5), white);

		.el-button {
			box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.1);
		}
	}
</style>