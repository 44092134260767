var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "startform_page" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm.active_step == 1
          ? _c("div", { staticClass: "startform-step step-one" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c("div", { staticClass: "start-icon" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.publicPath + "img/learning-icon.svg",
                      alt: ""
                    }
                  })
                ])
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "22px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.company.small_name
                        ? "Welcome to " +
                            _vm.company.small_name +
                            " Carpooling!"
                        : "Welcome to carpooling!"
                    )
                  )
                ]
              ),
              _vm._m(0),
              _c(
                "div",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Full name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Your full name" },
                        model: {
                          value: _vm.info.full_name,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "full_name", $$v)
                          },
                          expression: "info.full_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "email",
                          placeholder: "Your college email address"
                        },
                        model: {
                          value: _vm.info.email,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "email", $$v)
                          },
                          expression: "info.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "form-item",
                    { attrs: { label: "Password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "Create a password for your account"
                        },
                        model: {
                          value: _vm.info.password,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "password", $$v)
                          },
                          expression: "info.password"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.agree_privacy_policy,
                      callback: function($$v) {
                        _vm.agree_privacy_policy = $$v
                      },
                      expression: "agree_privacy_policy"
                    }
                  }),
                  _vm._v("  "),
                  _c(
                    "span",
                    [
                      _vm._v("I have read and accept the "),
                      _c(
                        "router-link",
                        {
                          staticClass: "highlighted",
                          attrs: { to: "/terms-conditions" }
                        },
                        [_vm._v("Terms and Conditions")]
                      ),
                      _vm._v(" and the "),
                      _c(
                        "router-link",
                        {
                          staticClass: "highlighted",
                          attrs: { to: "/privacy-policy" }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(".")
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.active_step == 2
          ? _c("div", { staticClass: "startform-step step-two" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_location" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("1. Where do you commute from?")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "We need this to find carpooling matches around your area, it won't be shared with anyone."
                    )
                  ]),
                  _c(
                    "el-input",
                    {
                      class: {
                        w100: true,
                        "validate-success": _vm.info.address.validated
                      },
                      staticStyle: { "margin-top": "8px" },
                      attrs: { placeholder: "Street name" },
                      on: { change: _vm.validateAddress },
                      model: {
                        value: _vm.info.address.input_address,
                        callback: function($$v) {
                          _vm.$set(_vm.info.address, "input_address", $$v)
                        },
                        expression: "info.address.input_address"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "append",
                            type: "primary",
                            icon: _vm.info.address.validated
                              ? "el-icon-check"
                              : ""
                          },
                          on: { click: _vm.validateAddress },
                          slot: "append"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.info.address.validated ? "Verified" : "Verify"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_organization" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("2. What location do you commute to?")
                  ]),
                  _vm._l(_vm.company.organizations, function(org_val, org_key) {
                    return _c(
                      "div",
                      { key: org_key },
                      _vm._l(org_val.locations, function(loc_val, loc_id) {
                        return _c(
                          "div",
                          { key: loc_id, staticClass: "surveyInputGroup" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.info.organization_location,
                                  expression: "info.organization_location"
                                }
                              ],
                              attrs: {
                                id: loc_id,
                                name: "survey-location",
                                type: "radio"
                              },
                              domProps: {
                                value: org_key + "###" + loc_id,
                                checked: _vm._q(
                                  _vm.info.organization_location,
                                  org_key + "###" + loc_id
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.info,
                                    "organization_location",
                                    org_key + "###" + loc_id
                                  )
                                }
                              }
                            }),
                            _c("label", { attrs: { for: loc_id } }, [
                              _vm._v(
                                _vm._s(
                                  Object.keys(_vm.company.organizations)
                                    .length > 1
                                    ? org_val.name + " - " + loc_val.name
                                    : loc_val.name
                                )
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_days_of_week" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("3. What days of the week?")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "fancy-checkboxes",
                      staticStyle: { "margin-top": "2px" }
                    },
                    [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.monday,
                              expression: "info.days_of_week.monday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_monday"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.info.days_of_week.monday)
                              ? _vm._i(_vm.info.days_of_week.monday, null) > -1
                              : _vm.info.days_of_week.monday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.monday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "monday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "monday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "monday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_monday" } },
                          [_vm._v("Monday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.tuesday,
                              expression: "info.days_of_week.tuesday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_tuesday"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.info.days_of_week.tuesday
                            )
                              ? _vm._i(_vm.info.days_of_week.tuesday, null) > -1
                              : _vm.info.days_of_week.tuesday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.tuesday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "tuesday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "tuesday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "tuesday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_tuesday" } },
                          [_vm._v("Tuesday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.wednesday,
                              expression: "info.days_of_week.wednesday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_wednesday"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.info.days_of_week.wednesday
                            )
                              ? _vm._i(_vm.info.days_of_week.wednesday, null) >
                                -1
                              : _vm.info.days_of_week.wednesday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.wednesday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "wednesday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "wednesday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.info.days_of_week,
                                  "wednesday",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_wednesday" } },
                          [_vm._v("Wednesday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.thursday,
                              expression: "info.days_of_week.thursday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_thursday"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.info.days_of_week.thursday
                            )
                              ? _vm._i(_vm.info.days_of_week.thursday, null) >
                                -1
                              : _vm.info.days_of_week.thursday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.thursday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "thursday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "thursday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "thursday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_thursday" } },
                          [_vm._v("Thursday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.friday,
                              expression: "info.days_of_week.friday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_friday"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.info.days_of_week.friday)
                              ? _vm._i(_vm.info.days_of_week.friday, null) > -1
                              : _vm.info.days_of_week.friday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.friday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "friday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "friday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "friday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_friday" } },
                          [_vm._v("Friday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.saturday,
                              expression: "info.days_of_week.saturday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_saturday"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.info.days_of_week.saturday
                            )
                              ? _vm._i(_vm.info.days_of_week.saturday, null) >
                                -1
                              : _vm.info.days_of_week.saturday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.saturday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "saturday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "saturday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "saturday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_saturday" } },
                          [_vm._v("Saturday")]
                        )
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.info.days_of_week.sunday,
                              expression: "info.days_of_week.sunday"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "days_of_week_checkbox_sunday"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.info.days_of_week.sunday)
                              ? _vm._i(_vm.info.days_of_week.sunday, null) > -1
                              : _vm.info.days_of_week.sunday
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.info.days_of_week.sunday,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "sunday",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.info.days_of_week,
                                      "sunday",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.info.days_of_week, "sunday", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { attrs: { for: "days_of_week_checkbox_sunday" } },
                          [_vm._v("Sunday")]
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_gotimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("4. Commuting to college (approx.)")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving home",
                              "picker-options": {
                                start: "05:30",
                                step: "00:15",
                                end: "15:00"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.go_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "go_leaving_time",
                                  $$v
                                )
                              },
                              expression: "info.commuting_times.go_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Trip duration"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times.go_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "go_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.go_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_backtimes" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("5. Commuting back home (approx.)")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { gutter: 20 }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { sm: 11 }
                        },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving office",
                              "picker-options": {
                                start: "12:00",
                                step: "00:15",
                                end: "23:45"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.back_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "back_leaving_time",
                                  $$v
                                )
                              },
                              expression:
                                "info.commuting_times.back_leaving_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 13 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Trip duration"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times
                                    .back_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "back_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.back_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_carpool_type" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("6. Carpool role")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Please choose if you would like to be a driver, a passenger or can be either."
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carpool_type,
                          expression: "info.carpool_type"
                        }
                      ],
                      attrs: {
                        id: "driver-option-type-survey",
                        name: "survey-carpool-type",
                        type: "radio",
                        value: "driver"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carpool_type, "driver")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carpool_type", "driver")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "driver-option-type-survey" } },
                      [_vm._v("Driver")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carpool_type,
                          expression: "info.carpool_type"
                        }
                      ],
                      attrs: {
                        id: "passenger-option-type-survey",
                        name: "survey-carpool-type",
                        type: "radio",
                        value: "passenger"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carpool_type, "passenger")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carpool_type", "passenger")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "passenger-option-type-survey" } },
                      [_vm._v("Passenger")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.carpool_type,
                          expression: "info.carpool_type"
                        }
                      ],
                      attrs: {
                        id: "both-option-type-survey",
                        name: "survey-carpool-type",
                        type: "radio",
                        value: "both"
                      },
                      domProps: {
                        checked: _vm._q(_vm.info.carpool_type, "both")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "carpool_type", "both")
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "both-option-type-survey" } }, [
                      _vm._v("Either")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_agegroup" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("7. What's your age group?")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "This helps us prioritise matches of people of similar age, where possible."
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "one-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 22,
                        checked: _vm._q(_vm.info.age, 22)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 22)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "one-option-age-survey" } }, [
                      _vm._v("Less than 26 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "two-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 30,
                        checked: _vm._q(_vm.info.age, 30)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 30)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "two-option-age-survey" } }, [
                      _vm._v("26 to 35 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "three-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 40,
                        checked: _vm._q(_vm.info.age, 40)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 40)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "three-option-age-survey" } }, [
                      _vm._v("36 to 45 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "four-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 50,
                        checked: _vm._q(_vm.info.age, 50)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 50)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "four-option-age-survey" } }, [
                      _vm._v("46 to 55 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "five-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 60,
                        checked: _vm._q(_vm.info.age, 60)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 60)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "five-option-age-survey" } }, [
                      _vm._v("56 to 65 years old")
                    ])
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.age,
                          expression: "info.age"
                        }
                      ],
                      attrs: {
                        id: "six-option-age-survey",
                        name: "survey-age-group",
                        type: "radio"
                      },
                      domProps: {
                        value: 70,
                        checked: _vm._q(_vm.info.age, 70)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "age", 70)
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "six-option-age-survey" } }, [
                      _vm._v("More than 65 years old")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_agegroup" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("8. What's your gender?")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "This helps us prioritise matches of people of same gender, where possible."
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "one-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "male"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "male") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "male")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "one-option-gender-survey" } },
                      [_vm._v("Male")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "two-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "female"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "female") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "female")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "two-option-gender-survey" } },
                      [_vm._v("Female")]
                    )
                  ]),
                  _c("div", { staticClass: "surveyInputGroup" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.gender,
                          expression: "info.gender"
                        }
                      ],
                      attrs: {
                        id: "three-option-gender-survey",
                        name: "survey-gender-group",
                        type: "radio",
                        value: "other"
                      },
                      domProps: { checked: _vm._q(_vm.info.gender, "other") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.info, "gender", "other")
                        }
                      }
                    }),
                    _c(
                      "label",
                      { attrs: { for: "three-option-gender-survey" } },
                      [_vm._v("Other / I prefer not to specify")]
                    )
                  ])
                ]
              ),
              _vm.info.carpool_type == "both" ||
              _vm.info.carpool_type == "driver"
                ? _c(
                    "div",
                    {
                      staticClass: "startform-group",
                      attrs: { id: "startform_car_reg" }
                    },
                    [
                      _c("span", { staticClass: "question-title" }, [
                        _vm._v("9. Your car registration plate")
                      ]),
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(
                          "If you park in carpooling-only places, we need this to verify you."
                        )
                      ]),
                      _c("el-input", {
                        staticClass: "w100",
                        staticStyle: { "margin-top": "8px" },
                        attrs: { placeholder: "191 D 1234" },
                        model: {
                          value: _vm.info.car_reg,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "car_reg", $$v)
                          },
                          expression: "info.car_reg"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.active_step == 3 && false
          ? _c("div", { staticClass: "startform-step step-three" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/testing-icon.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 0 0"
                  }
                },
                [_vm._v("You're nearly there!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "We only need these last few details to complete your profile."
                  )
                ]
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_description" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("Tell us a bit about yourself")
                  ]),
                  _c("el-input", {
                    staticClass: "w100",
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      rows: 3,
                      type: "textarea",
                      placeholder:
                        "(optional) What do you think your future carpooling buddy would like to know about you?"
                    },
                    model: {
                      value: _vm.info.description,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "description", $$v)
                      },
                      expression: "info.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "startform-group",
                  attrs: { id: "startform_phone_number" }
                },
                [
                  _c("span", { staticClass: "question-title" }, [
                    _vm._v("Your phone number")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Needed to arrange pickup information with your carpooling partners."
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "margin-top": "8px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "90px" },
                          model: {
                            value: _vm.info.phone_number_code,
                            callback: function($$v) {
                              _vm.$set(_vm.info, "phone_number_code", $$v)
                            },
                            expression: "info.phone_number_code"
                          }
                        },
                        _vm._l(_vm.getCountries, function(country) {
                          return _c("el-option", {
                            key: country,
                            attrs: { label: country, value: country }
                          })
                        }),
                        1
                      ),
                      _c("el-input", {
                        class: {
                          validated:
                            _vm.formatted_phone_number &&
                            _vm.formatted_phone_number.isValid(),
                          "not-validated":
                            (!_vm.formatted_phone_number ||
                              !_vm.formatted_phone_number.isValid()) &&
                            _vm.info.phone_number
                        },
                        attrs: { placeholder: "08XXXXXXXX" },
                        model: {
                          value: _vm.info.phone_number,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "phone_number", $$v)
                          },
                          expression: "info.phone_number"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm.info.carpool_type != "passenger"
                ? _c(
                    "div",
                    {
                      staticClass: "startform-group",
                      attrs: { id: "startform_car_reg" }
                    },
                    [
                      _c("span", { staticClass: "question-title" }, [
                        _vm._v("Your car registration plate")
                      ]),
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(
                          "If you park in carpooling-only places, we need this to verify you."
                        )
                      ]),
                      _c("el-input", {
                        staticClass: "w100",
                        staticStyle: { "margin-top": "8px" },
                        attrs: { placeholder: "191 D 1234" },
                        model: {
                          value: _vm.info.car_reg,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "car_reg", $$v)
                          },
                          expression: "info.car_reg"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.active_step == 4
          ? _c("div", { staticClass: "startform-step step-four" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Thanks for joining!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v(
                    "Once you log in and verify your profile, you will enter the draw of a €50 gift card when we launch."
                  )
                ]
              ),
              _vm._m(1),
              _vm.isPWA()
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-center": "center",
                        "justify-content": "center",
                        "margin-top": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onLoginClicked }
                        },
                        [_vm._v("Go to app")]
                      )
                    ],
                    1
                  )
                : !_vm.isPWA() &&
                  _vm.getOperatingSystem() == "iOS" &&
                    _vm.getOperatingSystem() == "Android"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-center": "center",
                        "justify-content": "center",
                        "margin-top": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/download")
                            }
                          }
                        },
                        [_vm._v("Download app")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "info", round: "", plain: "" },
                          on: { click: _vm.onLoginClicked }
                        },
                        [_vm._v("Login instead")]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-center": "center",
                        "justify-content": "center",
                        "margin-top": "20px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color",
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/download")
                            }
                          }
                        },
                        [_vm._v("How to download app")]
                      )
                    ],
                    1
                  )
            ])
          : _vm._e()
      ]),
      _vm.button_text[_vm.active_step - 1]
        ? _c("div", { staticClass: "form-navigation-buttons" }, [
            _c(
              "div",
              { staticClass: "navigation-button" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "secondary-color",
                    staticStyle: { "font-size": "17px" },
                    attrs: {
                      disabled: _vm.loading,
                      type: "primary",
                      round: ""
                    },
                    on: { click: _vm.onSubmitForm }
                  },
                  [
                    _vm._v(_vm._s(_vm.button_text[_vm.active_step - 1]) + "  "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon-arrow-right"
                    })
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { display: "block", "text-align": "center" } },
      [
        _vm._v(
          "To participate in this pilot you need to be willing to provide or accept a lift in your or your companion’s car on the commute to and from DCU.  The app will help you find other commuters to share your journey with. You will always be free to accept or reject other people’s requests to carpool."
        ),
        _c("br"),
        _vm._v(
          "Please complete the signup form if you are interested in participating. Make sure to "
        ),
        _c("b", [_vm._v("use you college email address")]),
        _vm._v(" to start.")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          display: "block",
          "text-align": "center",
          "margin-top": "15px"
        }
      },
      [
        _vm._v("You can download the app on your "),
        _c("span", { staticStyle: { "font-weight": "500" } }, [
          _vm._v("iPhone")
        ]),
        _vm._v(" or "),
        _c("span", { staticStyle: { "font-weight": "500" } }, [
          _vm._v("Android Phone")
        ]),
        _vm._v(".")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }